<template>
  <form
    id="filter"
    autocomplete="off"
    class="md:block pl-3 pr-3 pt-5 pb-0.5 md:pr-5 md:pl-5 md:pt-5 md:pb-0.5 mt-3 md:mt-5 lg:w-80 bg-slate-100 dark:bg-slate-800 rounded-md shadow-md"
    :style="`${windowWidth < 1024 && showFilters === 'false' && 'display: none'}`"
  >
    <div class="grid gap-2 md:gap-5 grid-cols-2">
      <label class="block mb-3 pl-1 font-bold text-slate-700 dark:text-slate-100">Filter</label>
      <label
        @click="onResetClicked"
        class="block mb-3 pl-1 text-sm font-normal text-slate-700 hover:text-slate-600 dark:text-slate-500 dark:hover:text-slate-400 text-right cursor-pointer"
      >
        Reset
      </label>
    </div>

    <label for="date_from" class="block mb-2 text-xs pl-1 font-bold text-slate-500">
      {{ isNameValueMode ? 'Value date' : 'Creation date' }}
    </label>
    <div class="grid gap-2 md:gap-5 mb-5 grid-cols-2 place-items-end">
      <div class="relative w-full">
        <VueDatePicker
          v-model="dateStart"
          :enable-time-picker="false"
          class="text-sm text-center w-full lg:text-base bg-slate-700 dark:bg-slate-900 text-slate-100 rounded-md focus:ring-blue-500 block mr-2 md:mr-0 placeholder-slate-400 dark:placeholder-slate-500"
          placeholder="START"
          min-date="2011-04-21"
          :prevent-min-max-navigation="true"
          :max-date="getDateStringFromDate(new Date())"
          :year-range="[2011, getDateStringFromDate(new Date()).substring(0, 4)]"
          @update:model-value="onDateStartChange"
          :teleport="true"
          :auto-position="false"
          position="left"
          :format="(date) => date && getDateStringFromDate(date)"
        >
          <template #input-icon><CalendarIcon /></template>
        </VueDatePicker>
      </div>

      <div class="relative w-full">
        <VueDatePicker
          v-model="dateEnd"
          :enable-time-picker="false"
          class="text-sm text-center w-full lg:text-base bg-slate-700 dark:bg-slate-900 text-slate-100 rounded-md focus:ring-blue-500 block mr-2 md:mr-0 placeholder-slate-400 dark:placeholder-slate-500"
          placeholder="END"
          min-date="2011-04-21"
          :prevent-min-max-navigation="true"
          :max-date="getDateStringFromDate(new Date())"
          :year-range="[2011, getDateStringFromDate(new Date()).substring(0, 4)]"
          @update:model-value="onDateEndChange"
          :teleport="true"
          :auto-position="false"
          position="right"
          :format="(date) => date && getDateStringFromDate(date)"
        >
          <template #input-icon><CalendarIcon /></template>
        </VueDatePicker>
      </div>
    </div>
    <label for="date_from" class="block mb-2 text-xs pl-1 font-bold text-slate-500">Results per page</label>

    <select
      v-model="limit"
      class="w-full mb-5 bg-slate-700 dark:bg-slate-800 text-slate-100 rounded-md border border-slate-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-center cursor-pointer"
    >
      <option v-for="option in [100, 150, 200, 250]" :key="`limit-options-${option}`" :value="option">
        {{ option }}
      </option>
    </select>

    <label for="letters_from" class="block mb-2 text-xs pl-1 font-bold text-slate-500">Options</label>

    <div class="flex items-center m-1 mb-5 whitespace-nowrap">
      <input
        type="checkbox"
        v-model="expired"
        class="w-4 h-4 text-blue-600 bg-slate-100 rounded border-slate-300 dark:bg-slate-700 dark:border-slate-600 ring-0 focus:ring-offset-0"
        style="box-shadow: none"
      />
      <label for="default-checkbox" class="ml-2 mr-3 text-xs font-bold text-slate-900 dark:text-slate-300">
        Expired
      </label>
      <input
        type="checkbox"
        v-model="wiki"
        class="w-4 h-4 text-blue-600 bg-slate-100 rounded border-slate-300 dark:bg-slate-700 dark:border-slate-600 ring-0 focus:ring-offset-0"
        style="box-shadow: none"
      />
      <label for="default-checkbox" class="ml-2 text-xs font-bold text-slate-900 dark:text-slate-300">Wiki</label>
      <input
        v-if="searchMode === SearchModes.name"
        type="checkbox"
        v-model="vaulted"
        class="ml-3 w-4 h-4 text-blue-600 bg-slate-100 rounded border-slate-300 dark:bg-slate-700 dark:border-slate-600 ring-0 focus:ring-offset-0"
        style="box-shadow: none"
      />
      <label
        v-if="searchMode === SearchModes.name"
        for="default-checkbox"
        class="ml-2 text-xs font-bold text-slate-900 dark:text-slate-300"
        >Vaulted</label
      >
    </div>
  </form>
</template>

<script>
import { nextTick } from 'vue'
import { useRoute } from 'vue-router'

import { defaultParams, getActiveParam, SearchParams, updateSearchParams } from '@/services/searchParams.js'
import { getDateStringFromDate } from '@/services/dateHelpers'
import CalendarIcon from '@/components/CalendarIcon.vue'
import useApp from '@/composables/useApp'
import useAssetList from '@/composables/useAssetList'
import useBreakpoints from '@/composables/useBreakpoints'
import SearchModes from '@/enums/SearchModes'

export default {
  components: { CalendarIcon },

  setup: () => {
    const {
      state: { showFilters },
    } = useApp()

    const route = useRoute()
    const { loadAssets, searchMode } = useAssetList(route)
    const { windowWidth } = useBreakpoints()

    return { showFilters, windowWidth, loadAssets, searchMode, SearchModes }
  },

  data: () => ({
    initialDateStart: getActiveParam(SearchParams.date_start),
    initialDateEnd: getActiveParam(SearchParams.date_end),
    dateStart: null,
    dateEnd: null,
    debounce: null,
    getDateStringFromDate,
  }),

  methods: {
    onResetClicked() {
      this.dateStart = null
      this.dateEnd = null

      nextTick(async () => {
        const options = [
          { key: SearchParams.date_start, value: '' },
          { key: SearchParams.date_end, value: '' },
          { key: SearchParams.page, value: '' },
          { key: SearchParams.expired, value: '' },
          { key: SearchParams.traits, value: '' },
          { key: SearchParams.wiki, value: '' },
          { key: SearchParams.vaulted, value: '' },
        ]

        if (!getActiveParam(SearchParams.trait) || getActiveParam(SearchParams.trait) === 'all') {
          options.push({ key: SearchParams.trait, value: '' })
        }

        await updateSearchParams(options)

        this.$emit('reset-filters')

        this.loadAssets()
      })
    },

    async onDateStartChange(e) {
      await updateSearchParams([
        { key: SearchParams.date_start, value: e ? getDateStringFromDate(e) : '' },
        { key: SearchParams.page, value: '' },
      ])
      this.loadAssets()
    },

    async onDateEndChange(e) {
      await updateSearchParams([
        { key: SearchParams.date_end, value: e ? getDateStringFromDate(e) : '' },
        { key: SearchParams.page, value: '' },
      ])
      this.loadAssets()
    },

    getButtonClass(filterName) {
      const activeClass =
        'sale text-small pt-2 pb-2 text-center uppercase bg-green-600 hover:bg-green-900 p-1 text-slate-100 rounded-md shadow-md cursor-pointer transition ease-out hover:scale-105 duration-500 border border-green-600'

      const inactiveClass =
        'expired text-small pt-2 pb-2 text-center uppercase bg-etherid-blue-900 dark:bg-etherid-blue-500 hover:bg-etherid-blue-600 dark:hover:bg-etherid-blue-600 p-1 text-slate-100 rounded-md shadow-md cursor-pointer transition ease-out hover:scale-105 duration-500 border border-etherid-blue-900'

      const url = new URL(window.location.href)
      const searchParamValue = url.searchParams.get(filterName)

      if (searchParamValue === 'true') return activeClass
      return inactiveClass
    },
  },

  computed: {
    limit: {
      get() {
        return Number(this.$route.query[SearchParams.limit] || defaultParams[SearchParams.limit])
      },

      async set(value) {
        await updateSearchParams([
          { key: SearchParams.limit, value },
          { key: SearchParams.page, value: '' },
        ])
        this.loadAssets()
      },
    },

    expired: {
      get() {
        return this.$route.query[SearchParams.expired] === 'true'
      },

      async set(value) {
        await updateSearchParams([
          { key: SearchParams.expired, value },
          { key: SearchParams.page, value: '' },
          { key: SearchParams.vaulted, value: '' },
        ])
        this.loadAssets()
      },
    },

    wiki: {
      get() {
        return this.$route.query[SearchParams.wiki] === 'true'
      },

      async set(value) {
        await updateSearchParams([
          { key: SearchParams.wiki, value },
          { key: SearchParams.page, value: '' },
        ])
        this.loadAssets()
      },
    },

    vaulted: {
      get() {
        return this.$route.query[SearchParams.vaulted] === 'true'
      },

      async set(value) {
        await updateSearchParams([
          { key: SearchParams.vaulted, value },
          { key: SearchParams.page, value: '' },
          { key: SearchParams.expired, value: '' },
        ])
        this.loadAssets()
      },
    },

    isNameValueMode() {
      return this.$route.query[SearchParams.search_mode] === 'nameValues'
    },
  },

  mounted() {
    if (this.initialDateStart) this.dateStart = this.initialDateStart
    if (this.initialDateEnd) this.dateEnd = this.initialDateEnd
  },

  watch: {
    '$route.query'() {
      const dateStart = getActiveParam(SearchParams.date_start)
      const dateStartInput = getDateStringFromDate(this.dateStart)
      if (dateStart !== dateStartInput) this.dateStart = dateStart || null

      const dateEnd = getActiveParam(SearchParams.date_end)
      const dateEndInput = getDateStringFromDate(this.dateEnd)
      if (dateEnd !== dateEndInput) this.dateEnd = dateEnd || null
    },
  },
}
</script>

<style>
.dp__input_icons {
  padding-right: 4px;
}

.dp__input_icon {
  left: 8px;
}

.dp__input_icon_pad {
  padding-left: 22px;
}

.dp__action_cancel {
  margin-right: 8px;
}

.dp__input {
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  color: rgb(241 245 249 / var(--tw-text-opacity));
  padding: 6px 0;
  text-align: center;
  border-color: rgb(71 85 105 / 0.8);
  border-width: 1px;
}

::placeholder {
  color: rgb(148 163 184) !important;
}

.dark .dp__input {
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.dp__main {
  border: none !important;
}
</style>
